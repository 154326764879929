import React from "react";
import Icon from "@ant-design/icons";

const SignOnBenefitsSvg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10327_12228)">
            <path d="M16.792 19.2109H1.62533C1.09199 19.2109 0.541992 18.8026 0.541992 18.1276V5.54427C0.541992 4.9776 1.05866 4.46094 1.62533 4.46094H7.04199C7.59199 4.46094 7.82533 4.76094 7.91699 4.94427L9.80033 7.64427C9.83366 7.69427 9.90866 7.71927 9.95866 7.71927H16.7087C17.242 7.71927 17.792 8.1276 17.792 8.8026V18.0526C17.842 18.3276 17.767 18.6359 17.5753 18.8693C17.3837 19.0943 17.1003 19.2193 16.792 19.2193V19.2109ZM1.62533 5.29427C1.52533 5.29427 1.37533 5.44427 1.37533 5.54427V18.1276C1.37533 18.3109 1.52533 18.3776 1.62533 18.3776H16.792C16.8503 18.3776 16.9087 18.3526 16.9337 18.3193C16.9753 18.2609 16.967 18.1609 16.9587 18.1109V8.79427C16.9587 8.61094 16.8003 8.54427 16.7003 8.54427H9.95033C9.64199 8.54427 9.24199 8.39427 9.07533 8.06094L7.19199 5.36094C7.15866 5.31094 7.13366 5.28594 7.03366 5.28594H1.62533V5.29427Z" fill="#2F66F2"/>
            <path d="M19.4167 16.2528H17.7333V15.4195H19.4167C19.6 15.4195 19.6667 15.2695 19.6667 15.1695V5.91947C19.6667 5.81947 19.6 5.7528 19.5 5.7528H12.75C12.4417 5.7528 12.0417 5.6028 11.875 5.26947L9.99167 2.56947C9.94167 2.5278 9.875 2.49447 9.75 2.49447H4.33333C4.15 2.49447 4.08333 2.64447 4.08333 2.74447V4.59447H3.25V2.74447C3.25 2.21113 3.65833 1.66113 4.33333 1.66113H9.75C10.0917 1.66113 10.375 1.7778 10.625 2.03613L12.5917 4.84447C12.625 4.89447 12.7 4.91947 12.75 4.91947H19.5C20.0583 4.91947 20.5 5.36113 20.5 5.91947V15.1695C20.5 15.7028 20.0917 16.2528 19.4167 16.2528Z" fill="#2F66F2"/>
        </g>
        <defs>
            <clipPath id="clip0_10327_12228">
                <rect width="20" height="20" fill="white" transform="translate(0.5 0.352539)"/>
            </clipPath>
        </defs>
    </svg>
);

const SignOnBenefits4Icon = (props: any) => (
    <Icon component={SignOnBenefitsSvg} {...props} />
);

export default SignOnBenefits4Icon;

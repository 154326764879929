import React from "react";
import Icon from "@ant-design/icons";

const SignOnBenefitSvg = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10327_12225)">
            <path d="M12.1999 19.2855H1.5166V0.452148H15.2666V9.98548H14.4333V1.28548H2.34993V18.4521H12.1999V19.2855Z" fill="#2F66F2"/>
            <path d="M17.0166 12.6195L14.1833 15.4529L12.8499 14.1195C12.6833 13.9529 12.5166 13.9529 12.3499 14.1195L11.3499 15.1195C11.2666 15.2028 11.2666 15.2862 11.2666 15.3695C11.2666 15.4529 11.3499 15.5362 11.3499 15.6195L13.8499 17.9529C13.9333 18.0362 14.0166 18.0362 14.0999 18.0362C14.1833 18.0362 14.2666 18.0362 14.3499 17.9529L18.4333 13.8695C18.5166 13.7862 18.5166 13.7028 18.5166 13.6195C18.5166 13.5362 18.4333 13.4528 18.4333 13.3695L17.5166 12.5362C17.3499 12.4528 17.1833 12.4529 17.0166 12.6195ZM17.6833 13.7029L14.1833 17.2862L12.1833 15.3695L12.6833 14.8695L14.0166 16.2029C14.1833 16.3695 14.3499 16.3695 14.5166 16.2029L17.4333 13.2862L17.6833 13.7029Z" fill="#2F66F2"/>
            <path d="M14.8503 20.1195C12.0003 20.1195 9.68359 17.8028 9.68359 14.9528C9.68359 12.1028 12.0003 9.78613 14.8503 9.78613C17.7003 9.78613 20.0169 12.1028 20.0169 14.9528C20.0169 17.8028 17.7003 20.1195 14.8503 20.1195ZM14.8503 10.6195C12.4586 10.6195 10.5169 12.5611 10.5169 14.9528C10.5169 17.3445 12.4586 19.2861 14.8503 19.2861C17.2419 19.2861 19.1836 17.3445 19.1836 14.9528C19.1836 12.5611 17.2419 10.6195 14.8503 10.6195Z" fill="#2F66F2"/>
            <path d="M8.80794 9.56055H7.97461V11.7522H8.80794V9.56055Z" fill="#2F66F2"/>
            <path d="M8.39154 10.3942C7.1582 10.3942 6.1582 9.54421 6.1582 8.51087V8.09421H6.99154V8.51087C6.99154 9.09421 7.61654 9.56087 8.39154 9.56087C9.16654 9.56087 9.79154 9.08587 9.79154 8.51087C9.79154 8.02754 9.3332 7.47754 8.71654 7.47754H8.0832C7.06654 7.47754 6.17487 6.60254 6.17487 5.61087C6.17487 4.61921 7.17487 3.72754 8.4082 3.72754C9.64154 3.72754 10.6415 4.57754 10.6415 5.61087V6.02754H9.8082V5.61087C9.8082 5.02754 9.1832 4.56087 8.4082 4.56087C7.6332 4.56087 7.0082 5.03587 7.0082 5.61087C7.0082 6.09421 7.46654 6.64421 8.0832 6.64421H8.71654C9.7332 6.64421 10.6249 7.51921 10.6249 8.51087C10.6249 9.50254 9.62487 10.3942 8.39154 10.3942Z" fill="#2F66F2"/>
            <path d="M8.80794 2.36914H7.97461V4.56081H8.80794V2.36914Z" fill="#2F66F2"/>
        </g>
        <defs>
            <clipPath id="clip0_10327_12225">
                <rect width="20" height="20" fill="white" transform="translate(0 0.352539)"/>
            </clipPath>
        </defs>
    </svg>
);

const SignOnBenefit3Icon= (props: any) => (
    <Icon component={SignOnBenefitSvg} {...props} />
);

export default SignOnBenefit3Icon;

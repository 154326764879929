import React from "react";
import Icon from "@ant-design/icons";

const SignOnBenefits1Svg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10327_12219)">
            <path d="M18.1169 11.2695H15.8086V10.4361H18.1169C18.9503 10.4361 19.6086 9.6528 19.6086 8.64447V5.53613C19.6086 4.5778 18.9086 3.74447 18.1169 3.74447H4.18359V2.91113H18.1169C19.3753 2.91113 20.4419 4.11113 20.4419 5.53613V8.64447C20.4419 10.1195 19.4169 11.2695 18.1169 11.2695Z" fill="#2F66F2"/>
            <path d="M18.4005 19.9777C17.2921 19.9777 16.3838 19.0693 16.3838 17.961C16.3838 16.8527 17.2921 15.9443 18.4005 15.9443C19.5088 15.9443 20.4171 16.8527 20.4171 17.961C20.4171 19.0693 19.5088 19.9777 18.4005 19.9777ZM18.4005 16.7777C17.7505 16.7777 17.2171 17.311 17.2171 17.961C17.2171 18.611 17.7505 19.1443 18.4005 19.1443C19.0505 19.1443 19.5838 18.611 19.5838 17.961C19.5838 17.311 19.0505 16.7777 18.4005 16.7777Z" fill="#2F66F2"/>
            <path d="M2.57526 5.34388C1.46693 5.34388 0.558594 4.43555 0.558594 3.32721C0.558594 2.21888 1.46693 1.31055 2.57526 1.31055C3.68359 1.31055 4.59193 2.21888 4.59193 3.32721C4.59193 4.43555 3.68359 5.34388 2.57526 5.34388ZM2.57526 2.14388C1.92526 2.14388 1.39193 2.67721 1.39193 3.32721C1.39193 3.97721 1.92526 4.51055 2.57526 4.51055C3.22526 4.51055 3.75859 3.97721 3.75859 3.32721C3.75859 2.67721 3.22526 2.14388 2.57526 2.14388Z" fill="#2F66F2"/>
            <path d="M10.4669 12.8605C9.35853 12.8605 8.4502 11.9521 8.4502 10.8438C8.4502 9.73548 9.35853 8.82715 10.4669 8.82715C11.5752 8.82715 12.4835 9.73548 12.4835 10.8438C12.4835 11.9521 11.5752 12.8605 10.4669 12.8605ZM10.4669 9.66048C9.81686 9.66048 9.28353 10.1938 9.28353 10.8438C9.28353 11.4938 9.81686 12.0271 10.4669 12.0271C11.1169 12.0271 11.6502 11.4938 11.6502 10.8438C11.6502 10.1938 11.1169 9.66048 10.4669 9.66048Z" fill="#2F66F2"/>
            <path d="M18.3915 10.4277H11.9082V11.2611H18.3915V10.4277Z" fill="#2F66F2"/>
            <path d="M16.5586 18.3773H3.13359C1.85026 18.3773 0.808594 17.2439 0.808594 15.8523V12.9439C0.808594 11.5523 1.85026 10.4189 3.13359 10.4189H8.86693V11.2523H3.13359C2.20859 11.2523 1.64193 12.1273 1.64193 12.9439V15.8523C1.64193 16.7856 2.30859 17.5439 3.13359 17.5439H16.5586V18.3773Z" fill="#2F66F2"/>
        </g>
        <defs>
            <clipPath id="clip0_10327_12219">
                <rect width="20" height="20" fill="white" transform="translate(0.5 0.352539)"/>
            </clipPath>
        </defs>
    </svg>
);

const SignOnBenefits1Icon = (props: any) => (
    <Icon component={SignOnBenefits1Svg} {...props} />
);

export default SignOnBenefits1Icon;
